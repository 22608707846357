<template>
  <div id="orderDetails">
    <van-nav-bar title="订单详情" left-arrow :border="false" @click-left="goLastPage">
    </van-nav-bar>
    <ProductInfor @buttonClick="goExpectPrice" buttonText="立即接单" @specilInfo="getSpecilInfo" />
  </div>
</template>
<script>
import ProductInfor from "@/components/order/ProductInfor.vue";
import { Dialog } from "vant";
export default {
  data() {
    return {
      isVerified: 0,
      orderImgList: [],
    };
  },
  components: {
    ProductInfor,
  },
  methods: {
    getSpecilInfo(e) {
      console.log(e);
      this.isVerified = e.worker_certification;
    },
    goExpectPrice(order_id) {
      switch (this.isVerified) {
        case 0:
          Dialog.alert({
            title: "实名认证",
            message: "您还没有实名认证！\n去实名认证吧",
            theme: "round-button",
            confirmButtonText: "去认证",
          }).then(() => {
            this.goSubPage("certification");
          });
          break;
        case 1:
          this.grabOrder(order_id)
          // this.goSubPage("quoting", this.$route.params.orderId);
          

          break;
        case 2:
          Dialog.alert({
            title: "审核中",
            message: "实名认证审核中，请耐心等待哦",
            theme: "round-button",
            confirmButtonText: "好的",
          }).then(() => {
            this.goSubPage("home");
          });
          break;
        case -1:
          Dialog.alert({
            title: "审核不通过",
            message: "很抱歉，您的实名认证不通过，请您按照错误提示再次提交",
            theme: "round-button",
            confirmButtonText: "好的",
          }).then(() => {
            this.goNextPage("/verified");
          });
          break;
      }
    },
    grabOrder(order_id) {
      this.$http
        .get(`/api/v2/worker/order/${order_id}/grab`)
        .then((res) => {
          this.$dialog.alert({
            title: '抢单成功',
            message: '抢单成功，请等待商家指派结果！',
            confirmButtonText: '确定',
            beforeClose: (action, done) => {
              this.goSubPage("home");
              done()
            },
            theme: 'round-button',
          }).then(() => {
            // on close
          });
          console.log('对啦', res)
        })
        .catch((err) => {
          this.$toast(err.error);
        });
    }
  },
};
</script>
<style lang="less">
</style>